



import { Component, Vue } from "vue-property-decorator";
import DisclosuresPackage from "@/components/disclosures-package/DisclosuresPackage.vue";

@Component({ components: { DisclosuresPackage } })
export default class BorrowerDisclosures extends Vue {
  public wesignParty: string = null;

  onChangeWesignParty(borrower) {
    this.wesignParty = borrower.name;
  }
}
